import React, { useState } from 'react';
import styles from './App.module.css';
import Split from 'react-split';

const GUTTER_SIZE = 10;

function App() {
    const [sizes, setSizes] = useState([50, 50]);
    const [gutterSize, setGutterSize] = useState(GUTTER_SIZE);

    const handleDoubleClick = () => {
        setSizes([50, 50]);
        setGutterSize(gutterSize === GUTTER_SIZE ? GUTTER_SIZE + 0.000000001 : GUTTER_SIZE);
    };

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const photonUrl = `${params.get('photonUrl')}/${window.location.search}`;
    const microBitUrl = `${params.get('microBitUrl')}/${window.location.search}`;

    return (
        <div className={styles.App}>
            <div className={styles.WebViewContainer}>
                <Split
                    sizes={sizes}
                    className={styles.Split}
                    minSize={0}
                    onDoubleClick={handleDoubleClick}
                    expandToMin={false}
                    gutterSize={gutterSize}
                    gutter={((index, direction) => {
                        const gutter = document.createElement('div');
                        gutter.className = `gutter gutter-${direction}`;

                        const inner = document.createElement('div');
                        inner.classList.add('gutter-inner');

                        gutter.appendChild(inner);

                        return gutter;
                    })}
                    gutterAlign="center"
                    snapOffset={30}
                    dragInterval={1}
                    direction="horizontal"
                    cursor="w-resize"
                >
                    <iframe title={'Robotics and Coding - Photon'} src={photonUrl}></iframe>
                    <iframe title={'Robotics and Coding - micro:bit'} src={microBitUrl}></iframe>
                </Split>
            </div>
        </div>
    );
}

export default App;
